// Created by SPe on 27/11/22
// Page to manage app settings
<template>
    <div class="settings">
        <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
        <NavBar 
            :devName="devName" 
            :showBackButton="showBackButton" 
            :showSpinner="showSpinner"
            :servConnected="servConnected" 
            :devConnected="devConnected"
        />
        
        <div v-if="Object.keys(this.configFromBackend).length" class="d-flex align-items-start">
            <div class="nav flex-column nav me-3" id="tab" role="tablist" aria-orientation="vertical">
                <button class="nav-link menuButton active" id="network-tab" data-bs-toggle="pill" data-bs-target="#network" type="button" role="tab" aria-controls="network" aria-selected="true">Network</button>
                <button class="nav-link menuButton" id="remote-tab" data-bs-toggle="pill" data-bs-target="#remote" type="button" role="tab" aria-controls="remote" aria-selected="false">Remote</button>
                <button class="nav-link menuButton" id="integration-tab" data-bs-toggle="pill" data-bs-target="#integration" type="button" role="tab" aria-controls="integration" aria-selected="false">Integration</button>
                <button class="nav-link menuButton" id="system-tab" data-bs-toggle="pill" data-bs-target="#system" type="button" role="tab" aria-controls="system" aria-selected="false">System</button>
            </div>
            <div class="tab-content" id="tabContent">
                <!-- ###############################################  Network Settings ############################################### -->
                <div class="tab-pane fade show active" id="network" role="tabpanel" aria-labelledby="network-tab">
                    <h2>Network Configuration</h2>
                    <!-- <h3>WiFi</h3>
                    <div id="wifiNetworks" style="overflow: hidden; width:100%">
                        <table style="height:6em; background-color: aquamarine;text-align: center;">
                        <thead style="vertical-align: top; font-size: 1.2em; font-weight: bold;"><tr><td>SSID</td><td>Channel</td><td>Speed</td><td>Signal</td><td>Security</td></tr></thead>
                        <tbody id="WiFiNetworkTableBody">
                            <tr v-for="wifi in wifiInfo" :key="wifi"><td>{{wifi["SSID"]}}</td><td>{{wifi["CHAN"]}}</td><td>{{wifi["RATE"]}}</td><td>{{wifi["SIGNAL"]}}</td><td>{{wifi["SECURITY"]}}</td></tr>
                        </tbody>
                        </table>
                    </div>
                    <small>Configure WiFi connection parameters. You can add up to 3 different WiFi Networks</small><br>
                    <hr><ul style="padding: 0;">
                        <li style="list-style:none">
                        <label for="wifi1ssid" class="configName">WiFi 1 SSID: </label>
                        <select name="wifi1ssid" v-model="wifi1ssid" class='wifiselect' @change="(e)=>{onOptionSelected(e.target.value, 'wifi1ssid', 'Network.WiFi.SSID1')}">
                            <option value="-Select-">-Select-</option>
                            <option v-for="wifi in wifiInfo" :key="wifi" :value="wifi['SSID']">{{wifi["SSID"]}}</option>
                        </select>
                        <input type="text" data-format="max50chars" id="wifi1ssid" @input="onInputChange" v-model="configInGUI['Network.WiFi.SSID1']" data-path="Network.WiFi.SSID1" placeholder="MyWiFi1"><br>
                        </li>
                        <li style="list-style:none">
                        <label for="wifi1passwd" class="configName">WiFi 1 Password: </label>
                        <input type="password" data-format="max50chars" id="wifi1passwd" @input="onInputChange" v-model="configInGUI['Network.WiFi.PASSWD1']" data-path="Network.WiFi.PASSWD1" placeholder="MyWiFi1Passwd">
                        <span class="password-showhide" ></span><span class="show-password">Show</span><span class="hide-password" style="display:none">hide</span>
                        </li>
                    </ul>

                    <hr><ul style="padding: 0;">
                        <li style="list-style:none">
                        <label for="wifi2ssid" class="configName">WiFi 2 SSID: </label>
                        <select name="wifi2ssid" v-model="wifi2ssid" class='wifiselect' @change="(e)=>{onOptionSelected(e.target.value, 'wifi2ssid', 'Network.WiFi.SSID2')}">
                            <option value="-Select-">-Select-</option>
                            <option v-for="wifi in wifiInfo" :key="wifi" :value="wifi['SSID']">{{wifi["SSID"]}}</option>
                        </select>
                        <input type="text" data-format="max50chars" id="wifi2ssid" @input="onInputChange" v-model="configInGUI['Network.WiFi.SSID2']" data-path="Network.WiFi.SSID2" placeholder="MyWiFi2"><br>
                        </li>
                        <li style="list-style:none">
                        <label for="wifi2passwd" class="configName">WiFi 2 Password: </label>
                        <input type="password" data-format="max50chars" id="wifi2passwd" @input="onInputChange" v-model="configInGUI['Network.WiFi.PASSWD2']" data-path="Network.WiFi.PASSWD2" placeholder="MyWiFi2Passwd">
                        <span class="password-showhide" ></span><span class="show-password">Show</span><span class="hide-password" style="display:none">hide</span>
                        </li>
                    </ul>

                    <hr><ul style="padding: 0;">
                        <li style="list-style:none">
                        <label for="wifi3ssid" class="configName">WiFi 3 SSID: </label>
                        <select name="wifi3ssid" v-model="wifi3ssid" class='wifiselect' @change="(e)=>{onOptionSelected(e.target.value, 'wifi3ssid', 'Network.WiFi.SSID3')}">
                            <option value="-Select-">-Select-</option>
                            <option v-for="wifi in wifiInfo" :key="wifi" :value="wifi['SSID']">{{wifi["SSID"]}}</option>
                        </select>
                        <input type="text" data-format="max50chars" id="wifi3ssid" @input="onInputChange" v-model="configInGUI['Network.WiFi.SSID3']" data-path="Network.WiFi.SSID3" placeholder="MyWiFi3"><br>
                        </li>
                        <li style="list-style:none">
                        <label for="wifi3passwd" class="configName">WiFi 3 Password: </label>
                        <input type="password" data-format="max50chars" id="wifi3passwd" @input="onInputChange" v-model="configInGUI['Network.WiFi.PASSWD3']" data-path="Network.WiFi.PASSWD3" placeholder="MyWiFi3Passwd">
                        <span class="password-showhide" ></span><span class="show-password">Show</span><span class="hide-password" style="display:none">hide</span>
                        </li>
                    </ul><hr>
                    <p>Select a connection type for WiFi:</p>
                    <div>
                        <input type="radio" id="wifidhcp" name="wifinettype" @input="onInputChange" v-model="configInGUI['Network.WiFi.ConType']" data-path="Network.WiFi.ConType" value="DHCP">
                        <label for="wifidhcp" name="wifinettype" class="configName">WiFi DHCP</label>        
                        <input type="radio" id="wifistatic" name="wifinettype" @input="onInputChange" v-model="configInGUI['Network.WiFi.ConType']" data-path="Network.WiFi.ConType" value="Static">
                        <label for="wifistatic" name="wifinettype" class="configName">WiFi Static</label>
                    </div>
                    <br>
                    <hr>
                    <div v-if="configInGUI['Network.WiFi.ConType']=='Static'" id="wifistaticip" class="wifistaticip">
                        <label for="wifiipAddress" class="configName">WiFi IP Address: </label>
                            <input type="text" data-format="cidr" id="wifiipaddress" name="wifiipaddress" @input="onInputChange" v-model="configInGUI['Network.WiFi.IpAddress']" data-path="Network.WiFi.IpAddress" placeholder="192.168.1.32/24"><small>Example: 192.168.1.32/24</small><br>
                        <label for="wifiipGateway" class="configName">WiFi IP Gateway: </label>
                            <input type="text" data-format="ip" id="wifiipgateway" name="wifiipgateway" @input="onInputChange" v-model="configInGUI['Network.WiFi.Gateway']" data-path="Network.WiFi.Gateway" placeholder="192.168.1.1"><small>Example: 192.168.1.1</small><br>
                        <label for="wifidns" class="configName">WiFi DNS: </label>
                            <input type="text" data-format="2ips" id="wifidns" name="wifidns" @input="onInputChange" v-model="configInGUI['Network.WiFi.DNS']" data-path="Network.WiFi.DNS" placeholder="1.1.1.1,1.0.0.1"><small>Example: 1.1.1.1,1.0.0.1</small><br>
                    </div> -->
                    <h3>Ethernet</h3>
                    <small>Configure Ethernet connection parameters</small>
                    <p>Select a connection type for Ethernet:</p>
                    <div>
                    <input type="radio" id="ethdhcp" name="ethnettype" @input="onInputChange" v-model="configInGUI['Network.Ethernet.ConType']" data-path="Network.Ethernet.ConType" value="DHCP">
                    <label for="ethdhcp" name="ethnettype" class="configName">Ethernet DHCP</label>        
                    <input type="radio" id="ethstatic" name="ethnettype" @input="onInputChange" v-model="configInGUI['Network.Ethernet.ConType']" data-path="Network.Ethernet.ConType" value="Static">
                    <label for="ethstatic" name="ethnettype" class="configName">Ethernet Static</label>
                    </div>
                    <br>
                    <hr>
                    <div v-if="configInGUI['Network.Ethernet.ConType']=='Static'" id="ethstaticip" class="ethstaticip">
                    <label for="ethipAddress" class="configName">Ethernet IP Address: </label>
                        <input type="text" data-format="cidr" id="ethipaddress" name="ethipaddress" @input="onInputChange" v-model="configInGUI['Network.Ethernet.IpAddress']" data-path="Network.Ethernet.IpAddress" placeholder="192.168.1.33/24"><small>Example: 192.168.1.33/24</small>
                    <br><i><label for="currenthipAddress" class="configName">Current Eth Ip Address: </label>
                        <input disabled type="text" id="currenthipAddress" name="currenthipAddress" v-model="configInGUI['Network.Ethernet.CurrentIpAddress']" data-path="Network.Ethernet.CurrentIpAddress">
                    </i>
                    <br>
                    <label for="ethipGateway" class="configName">Ethernet IP Gateway: </label>
                        <input type="text" data-format="ip" id="ethipgateway" name="ethipgateway" @input="onInputChange" v-model="configInGUI['Network.Ethernet.Gateway']" data-path="Network.Ethernet.Gateway" placeholder="192.168.1.1"><small>Example: 192.168.1.1</small>
                    <br><i><label for="currentgateway" class="configName">Current Eth Gateway: </label>
                        <input disabled type="text" id="currentgateway" name="currentgateway" v-model="configInGUI['Network.Ethernet.CurrentGateway']" data-path="Network.Ethernet.CurrentGateway">
                    </i>
                    <label for="ethdns" class="configName">Ethernet DNS: </label>
                        <input type="text" data-format="2ips" id="ethdns" name="ethdns" @input="onInputChange" v-model="configInGUI['Network.Ethernet.DNS']" data-path="Network.Ethernet.DNS" placeholder="1.1.1.1,1.0.0.1"><small>Example: 1.1.1.1,1.0.0.1</small>
                    <br><i><label for="currentdns" class="configName">Current Eth DNS: </label>
                        <input disabled type="text" id="currentdns" name="currentdns" v-model="configInGUI['Network.Ethernet.CurrentDNS']" data-path="Network.Ethernet.CurrentDNS">
                    </i>
                    </div>
                    <div class="p-1 flex-parent jc-center">
                    <button class="actionButton" id="saveNetworkConfig" type="button" @click="setConfiguration('Network')" style="align: center">Save and apply Network Configuration</button>
                    </div>
                </div>
                <!-- ###############################################  Remote Settings ############################################### -->
                <div class="tab-pane fade" id="remote" role="tabpanel" aria-labelledby="remote-tab">
                    <h2>Remote Connection Configuration</h2>
                    <h3>Users allowed to remote control</h3>
                    <p>Add email of users that are allowed to remote view and control the device</p>
                    <div id="allowedusers" class="allowedusers">
                        <label for="alloweduser1" class="configName">Email User 1: </label>
                            <input type="email" data-format="emptyoremail" id="alloweduser1" name="alloweduser1" @input="onInputChange" v-model="configInGUI['Remote.Users.1']" data-path="Remote.Users.1" placeholder="user1@example.com"><small>Eg.: user1@example.com</small><br>
                        <label for="alloweduser2" class="configName">Email User 2: </label>
                            <input type="email" data-format="emptyoremail" id="alloweduser2" name="alloweduser2" @input="onInputChange" v-model="configInGUI['Remote.Users.2']" data-path="Remote.Users.2" placeholder="user2@example.com"><small>Eg.: user2@example.com</small><br>
                        <label for="alloweduser3" class="configName">Email User 3: </label>
                            <input type="email" data-format="emptyoremail" id="alloweduser3" name="alloweduser3" @input="onInputChange" v-model="configInGUI['Remote.Users.3']" data-path="Remote.Users.3" placeholder="user3@example.com"><small>Eg.: user3@example.com</small><br>
                        <label for="alloweduser4" class="configName">Email User 4: </label>
                            <input type="email" data-format="emptyoremail" id="alloweduser4" name="alloweduser4" @input="onInputChange" v-model="configInGUI['Remote.Users.4']" data-path="Remote.Users.4" placeholder="user4@example.com"><small>Eg.: user4@example.com</small><br>
                        <label for="alloweduser5" class="configName">Email User 5: </label>
                            <input type="email" data-format="emptyoremail" id="alloweduser5" name="alloweduser5" @input="onInputChange" v-model="configInGUI['Remote.Users.5']" data-path="Remote.Users.5" placeholder="user5@example.com"><small>Eg.: user5@example.com</small><br>
                    </div>
                    <br>
                    <div class="flex-parent jc-center">
                        <button class="actionButton" id="saveRemoteConfig" type="button" @click="setConfiguration('Remote')" style="align: center">Save Remote Configuration</button>
                    </div>
                </div>
                <!-- ###############################################  Integration Settings ############################################### -->
                <div class="tab-pane fade" id="integration" role="tabpanel" aria-labelledby="integration-tab">
                    <h2>Integration Configuration</h2>
                    <h3>Status Notification API</h3>
                    <div id="StatusNotificationAPIDiv" class="StatusNotificationAPIDiv">
                    <label for="statusNotificationAPI" class="configName">Status API URL: </label>
                        <input type="text" data-format="max200chars" id="notificationurl" name="notificationurl" @input="onInputChange" v-model="configInGUI['Integration.StatusNotification.URL']"  data-path="Integration.StatusNotification.URL"><small>Eg.: http://192.168.1.23/panelInfo</small><br>
                    </div>
                    <div><small>This Api URL will be called every time keypad status change.</small></div>
                    <br>
                    <h3>Start Remote API</h3>
                    <div id="StartRemoteAPIDiv" class="StartRemoteAPIDiv">
                    <label for="startRemoteAPI" class="configName">Start Remote API URL: </label>
                        <input type="text" data-format="max200chars" id="StartRemoteApiUrl" name="StartRemoteApiUrl" @input="onInputChange" v-model="configInGUI['Integration.StartRemote.URL']"  data-path="Integration.StartRemote.URL"><small>Eg.: http://192.168.1.23/ackproblem</small><br>
                    </div>
                    <div><small>This Api URL will be call every time the keypad status change to REMOTE.</small></div>
                    <br>
                    <br>
                    <div class=" p-1 flex-parent jc-center">
                    <button class="actionButton" id="saveIntegrationConfig" type="button" @click="setConfiguration('Integration')" style="align: center">Save Integration Configuration</button>
                    </div>
                    <div class="p-1 flex-parent jc-center">
                    <button class="actionButton" id="restartApp" type="button" @click="restartApp" style="align: center">Restart Application</button>
                    </div>
                </div>
                <!-- ###############################################  System Settings ############################################### -->
                <div class="tab-pane fade" id="system" role="tabpanel" aria-labelledby="system-tab">
                    <h2>System Configuration</h2>
                    <h3>Device Identificarion</h3>
                    <div id="deviceIdDiv" class="deviceIdDiv">
                        <label for="deviceId" class="configName">Device Id: </label>
                            <input disabled type="text" id="deviceId" name="deviceId" @input="onInputChange" v-model="configInGUI['System.Device.ID']"  data-path="System.Device.ID" placeholder="A1A1A1"><br>
                    </div>
                    <div><small>Identification of the device. Can not be changed.</small></div>
                    <br>
                    <hr><br>
                    <div id="deviceOwnerDiv" class="deviceOwnerDiv">
                    <label for="deviceOwner" class="configName">Device Owner: </label>
                        <input type="text" data-format="max50chars" id="deviceOwner" name="deviceOwner" @input="onInputChange" v-model="configInGUI['System.Device.Owner']"  data-path="System.Device.Owner" placeholder="MyCompany"><small>Eg.: MyCompany</small><br>
                    </div>
                    <div><small>Name of the owner. E.g. Name of the company.</small></div>
                    <br>
                    <hr><br>
                    <div id="deviceNameDiv" class="deviceNameDiv">
                    <label for="deviceName" class="configName">Device Name: </label>
                        <input type="text" data-format="max50restchars" id="deviceName" name="deviceName" @input="onInputChange" v-model="configInGUI['System.Device.Name']"  data-path="System.Device.Name" placeholder="Device1"><small>Eg.: Device1</small><br>
                    </div>
                    <div><small>Name of the device. Set a meaningfull name for you.</small></div>
                    <hr><br>
                    <div id="deviceShortNameDiv" class="deviceShortNameDiv">
                    <label for="deviceShortName" class="configName">Device Short Name: </label>
                        <input type="text" data-format="max50chars" id="deviceShortName" name="deviceShortName" @input="onInputChange" v-model="configInGUI['System.Device.ShortName']"  data-path="System.Device.ShortName" placeholder="D1"><small>Eg.: D1</small><br>
                    </div>
                    <div><small>Short name of the device. Set a meaningfull name for you. Recomended max 2-3 characters</small></div>
                    <hr><br>
                    <div id="deviceProfileDiv" class="deviceProfileDiv">
                    <label for="deviceProfile" class="configName">Device Profile: </label>
                        <input type="text" data-format="max50chars" id="deviceProfile" name="deviceProfile" @input="onInputChange" v-model="configInGUI['System.Device.Profile']"  data-path="System.Device.Profile" placeholder="Profile1"><small>Eg.: Profile1</small><br>
                    </div>
                    <div><small>Profile of the device.</small></div>
                    <br>
                    <hr>
                    <h3>Software Upgrade</h3>
                    <div style="display: flex; justify-content: space-between;" id="CurrentSwPkg" class="CurrentSwPkg">
                        <p class="configName">Current Sw Package: &nbsp;&nbsp;</p><p data-path="System.CurrentSwPkg">{{currSwPackName}}</p>
                    </div>
                    <br>
                    <hr>
                    <div style="display: flex; justify-content: space-between;" id="LatestSwPkg" class="LatestSwPkg">
                        <p class="configName">Latest available Sw Package: &nbsp;&nbsp;</p><p data-path="System.LatestSwPkg">{{latestSwPackageName}}</p>
                        <button class="actionButton pi pi-sync" style="height: 1.6em; font-size: 1rem;" @click="getLatestSwPackageName"></button>
                    </div>
                    <br>
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="saveCamerasConfig" type="button" @click="setConfiguration('System')" style="align: center">Save System Configuration</button>
                    </div>
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="checkForSwUpgrade" type="button" @click="checkForSwUpgrade" style="align: center" :disabled="disableCheckForSwUpgrade">Upgrade Software</button>
                    </div>
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="restartApp" type="button" @click="restartApp" style="align: center">Restart Application</button>
                    </div>
                    <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="rebootSystem" type="button" @click="rebootSystem" style="align: center">Reboot AI Computer</button>
                    </div>
                    <!-- <div class="p-1 flex-parent jc-center">
                        <button class="actionButton" id="factoryReset" type="button" @click="factoryReset" style="align: center">Factory Reset</button>
                    </div> -->
                </div>
            </div>
        </div>
        <br class="my-2">
        <!-- Footernbar -->
        <Footer />              
    </div>  
</template>

<script>
//import { useLoading } from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import LoadingOverlay from '@/components/LoadingOverlay.vue'; 
import { Options, Vue } from 'vue-class-component'; 
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import store from '@/store/index.js';
import appConfig from '@/config.js';
import { relativeDateTime } from '@/library/utils'
import { registerUnifiedMessageCallBack } from '@/library/client-unified-receive'
import { openUnifiedChannel, sendMessageUnified, closeChannel } from '@/library/client-unified-send'


@Options({
    components: {
        LoadingOverlay,
        NavBar,
        Footer,
    },
    data: function(){
        return {
            loadingOverlay: {show: false, text: 'Loading'},
            countDown: null,
            showBackButton: false,
            showSpinner: false,
            devId: this.$route.params.DevId, // DevId collected from Path Parameters
            devName: this.$route.params.DevName, // DevId collected from Path Parameters
            configFromBackend: {}, // Config as is taken from device
            configInGUI: {}, // Config in GUI
            wifiInfo: null, // Wifi info received from device
            wifiSSIDs: null, // List of WiFI SSIDs
            currSwPackName: 'Loading...', // Current Sw package Name
            latestSwPackageName: 'Loading...', // Latest available Sw package name
            wifi1ssid: '-Select-', // Selected wifi 1 ssid
            wifi2ssid: '-Select-', // Selected wifi 2 ssid
            wifi3ssid: '-Select-', // Selected wifi 3 ssid

            getUserConfigTask: null, // setInterval task to get user config until is done
            getCurrentSwPackageNameTask: null, // setInterval task to get current sw package until is done
            getLatestSwPackageNameTask: null, // setInterval task to get latest sw package until is done
            getWiFiInfoTask: null, // setInterval task to get wifi info from device

            loaderTask: null,
            reloadTask: null,
            
            getDataTasksCreated: false, // Flag to indicate whether getDatatasks has been created
        }
    },
    props: [],
    methods: {
        onDevConnected() {
            console.log(`Device: ${this.devId} is connected`);
            this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};
            // Register Call-Backs
            registerUnifiedMessageCallBack('setUserConfig', this.onSetUserConfig);
            registerUnifiedMessageCallBack('setWiFiNetworks', this.onSetWiFiNetworks);
            registerUnifiedMessageCallBack('setCurrentSwPackageName', this.onSetCurrentSwPackageName);
            registerUnifiedMessageCallBack('setLatestSwPackageName', this.onSetLatestSwPackageName);
            // Create (if not yet getDataTasks)
            if (!this.getDataTasksCreated) {
                this.createGetDataTasks();
                this.getDataTasksCreated = true;
            } else console.log(`createGetDataTasks already created`);  
        },
        onSetUserConfig(devId, payLoad) {
            console.log(`setUserConfig Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            this.showSpinner = false;
            this.loadingOverlay.show = false;
            for (let config in payLoad) {
                let firstTime = ! (config in this.configInGUI);
                this.configFromBackend[config] = payLoad[config];
                this.configInGUI[config] = payLoad[config];
                // Call change event if not first time
                if (! firstTime) {
                    var elements = document.querySelectorAll(`[data-path="${config}"]`)
                    if (elements.length > 0) {
                        var element = elements[0];
                        let e = {target: element};
                        this.onInputChange(e);
                    }
                }
            }
        },
        onSetWiFiNetworks(devId, payLoad) {
            console.log(`setWiFiNetworks Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            this.showSpinner = false;
            this.wifiInfo = [];
            this.wifiSSIDs = []; 
            //let wifiInUse = null;
            payLoad.forEach((wifi) => {
                if (this.wifiSSIDs.includes(wifi["SSID"])) return; // Skip it if alredy in list
                //if (wifi["IN-USE"] === "*") wifiInUse = wifi["SSID"]; // Detect with (if any) SSID is connected
                else if (wifi["IN-USE"] === "IN-USE");// Header repetaed. Do nothing
                else if (wifi["SSID"] === "--" && wifi["SECURITY"] === "--");// Phantom network. skip it
                else {
                    // Add to Available WiFi table
                    this.wifiInfo.push(wifi);
                    // Add to wifi selects if not open
                    //if (!$('wifiselect').data('open')) $('.wifiselect').append($('<option>', {value: wifi["SSID"], text: wifi["SSID"]}));
                    this.wifiSSIDs.push(wifi["SSID"]); // Add to the list
                }
            });
            if (this.loaderTask) clearTimeout(this.loaderTask); // Cancel task if any
            this.loaderTask = setTimeout(() => {
                console.error(`No data received from device: ${devId} in last 10 seconds`);
                this.showSpinner = true;
            }, 10000);
            
        },
        onSetCurrentSwPackageName(devId, payLoad) {
            console.log(`setCurrentSwPackageName Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            this.currSwPackName = payLoad['CurrentSwPackageName'];            
        },
        onSetLatestSwPackageName(devId, payLoad) {
            console.log(`setLatestSwPackageName Received fron device: ${devId} with PayLoad: ${JSON.stringify(payLoad)}`);
            this.latestSwPackageName = payLoad['LatestSwPackageName'];
        },
        createGetDataTasks() {
            // Get config data
            const content = {ParRootPath: ''};
            // Repeat every 2 seconds until data is taken
            sendMessageUnified(this.devId, 'getUserConfig', content);
            this.getUserConfigTask = setInterval(() => {
                if (Object.keys(this.configFromBackend).length === 0) sendMessageUnified(this.devId, 'getUserConfig', content);
                else clearInterval(this.getUserConfigTask);
            }, 2000);
            // Repeat every 2 seconds until data is taken
            sendMessageUnified(this.devId, 'getCurrentSwPackageName', {});
            this.getCurrentSwPackageNameTask = setInterval(() => {
                console.log(`this.currSwPackName: ${this.currSwPackName}`)
                if (! this.currSwPackName || this.currSwPackName.includes('Loading')) sendMessageUnified(this.devId, 'getCurrentSwPackageName', {});
                else clearInterval(this.getCurrentSwPackageNameTask);
            }, 2000);
            // Repeat every 2 seconds until data is taken
            sendMessageUnified(this.devId, 'getLatestSwPackageName', {});
            this.getLatestSwPackageNameTask = setInterval(() => {
                if (! this.latestSwPackageName || this.currSwPackName.includes('Loading')) sendMessageUnified(this.devId, 'getLatestSwPackageName', {});
                else clearInterval(this.getLatestSwPackageNameTask);
            }, 2000);
            // // Get periodically wifi networks
            // this.getWiFiInfoTask = setInterval(() => {
            //     if (this.getWiFiInfoTask) {
            //         if (document.visibilityState === "visible") {
            //             console.log(`Getting WiFi Info from device`);
            //             sendMessageUnified(this.devId, 'getWiFiNetworks', {});
            //         }
            //     } else {
            //         console.log(`XXXXXXXXXXXXXXXXXXXX  This message should not appear    XXXXXXXXXXXXXXXXX`);
            //         clearInterval(this.getWiFiInfoTask);
            //         this.getWiFiInfoTask = null;
            //     }
            // }, 5000); // Repeat every 5 seconds
        },
        relativeDateTime(TimeStampMs, seconds) {
            return relativeDateTime(TimeStampMs, seconds);
        },
        round(number, digits) {
            if (number) return number.toFixed(digits);
            else return 0;
        },
        setConfiguration(rootPath) {
            if (this.checkSubConfigurationOnlyChanges(rootPath)) { // Chaeck if all configuration are valid
                console.log(`setConfiguration for path prefix: ${rootPath}`);
                var newConfig = this.getSubConfigurationOnlyChanges(rootPath)
                console.log(`setConfiguration. Setting newConfig: ${JSON.stringify(newConfig)}`);
                if (Object.keys(newConfig).length > 0) sendMessageUnified(this.devId, 'setUserConfig', newConfig);
                // In case Network configuration --> force reload the page
                if (rootPath == 'Network') {
                    alert("Network configuration will be apply inmediatly and application will restart");
                    this.$router.go();
                }
            }
        },
        getSubConfigurationOnlyChanges(rootPath) {
            var out = {}
            //console.log(`rootPath: ${rootPath}`)
            for (var path in this.configFromBackend) { // Loop over all possible configurations
                if (path.startsWith(rootPath)) {
                    var oldValue = this.configFromBackend[path];
                    var newValue = this.configInGUI[path];
                    console.log(`Page Config. Path: ${path} Old Value: ${oldValue}, New Value: ${newValue}}`)
                    if (newValue !== undefined &&  newValue !== oldValue) {
                        out[path] = newValue;
                        console.log(`Adding configuration for path: ${path}, Value: ${out[path]}, Old Value: ${oldValue}`);
                    }
                }
            }
            return out;
        },
        checkSubConfigurationOnlyChanges(rootPath) {
            for (var path in this.configFromBackend) { // Loop over all possible configurations
                if (path.startsWith(rootPath)) {
                    var format = this.getFormatFromUI(path);
                    var oldValue = this.configFromBackend[path];
                    var newValue = this.configInGUI[path];
                    if (newValue !== undefined &&  newValue !== oldValue) {
                        if (! this.checkFormat(format, newValue)) {
                            console.log(`Configuration for: ${path} is invalid: ${newValue} with format: ${format}`);
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        getFormatFromUI(path) {
            var elements = document.querySelectorAll(`[data-path="${path}"]`);
            if (elements.length > 0) {
                return elements[0].getAttribute("data-format");
            }
        },
        onOptionSelected(value, targeId, targetPath) {
            console.log(`On Option Selected. Value: ${value}, Target Id: ${targeId}, Target Path: ${targetPath}`);
            if (value !== '-Select-') {
                this.configInGUI[targetPath] = value;
                let targetElement = document.querySelector(`input[id="${targeId}"]`);
                targetElement.value = value;
                let e = {target: targetElement};
                this.onInputChange(e);
            }
        },
        onInputChange(e) {
            console.log(`onInputChange on: ${e.target.getAttribute('data-path')}, Name: ${e.target.name}, Value: ${e.target.value}, Type: ${e.target.type}, Format: ${e.target.getAttribute('data-format')}`);
            var path = e.target.getAttribute('data-path');
            var oldValue = this.configFromBackend[path];
            var newValue = e.target.value;
            if (e.target.type === 'radio') {
                newValue = this.configInGUI[e.target.getAttribute('data-path')]
                console.log(`Radio in path: ${e.target.getAttribute('data-path')} with value: ${newValue}`);
            }            
            if (e.target.getAttribute("content-type") === 'number') newValue = Number(newValue);            
            console.log(`Old: ${oldValue} (${typeof oldValue}), New: ${newValue} (${typeof newValue}), Different: ${newValue !== oldValue}`);
            if (String(newValue) !== String(oldValue)) {
                if (e.target.type === 'radio') e.target.nextElementSibling.style.backgroundColor = '#d2ff4d'; // Yellow
                else {
                    if (this.checkFormat(e.target.getAttribute('data-format'), String(newValue))) e.target.style.backgroundColor = "#d2ff4d"; // Yellow
                    else e.target.style.backgroundColor = "#ff531a"; // Red
                }
            } else {  // Same value as in global configuration
                if (e.target.type === 'radio') {
                    //var divParent = e.target.parentElement;
                    var allLabels = document.querySelectorAll(`label[name="${e.target.name}"]`)
                    allLabels.forEach(label => { label.style.backgroundColor = "white";});
                } else {
                    if (this.checkFormat(e.target.getAttribute('data-format'), String(newValue))) e.target.style.backgroundColor = "white"; // Yellow
                    else e.target.style.backgroundColor = "#ff531a"; // Red
                }
            }
            // Assign
            this.configInGUI[path] = newValue;
        },
        getCurrentSwPackageName() {
            console.log(`getCurrentSwPackageName`);
            sendMessageUnified(this.devId, 'getCurrentSwPackageName', {});
        },
        getLatestSwPackageName() {
            console.log(`getLatestSwPackageName`);
            this.latestSwPackageName = 'Loading...';
            sendMessageUnified(this.devId, 'getLatestSwPackageName', {});
        },
        checkForSwUpgrade() {
            console.log(`checkForSwUpgrade`);
            let text = "Do you really want to install latest software version?";
            if (confirm(text) == true) {
                sendMessageUnified(this.devId, 'checkForSwUpgrade', {});
                this.stopCommunication();
                this.loadingOverlay = {show: true, text: `Upgrading Software... System will restart soon`};
                this.reloadTask = setTimeout(()=>{window.location.reload();}, 100000);
            } else {
                console.log(`Check for Software Upgrade was cancelled`)
            }              
        },
        restartApp() {
            console.log(`restartApp`);
            let text = "Do you really want to restart application?";
            if (confirm(text) == true) {
                sendMessageUnified(this.devId, 'restartApp', {});
                this.stopCommunication();
                this.loadingOverlay = {show: true, text: `Restarting application...`};
                this.reloadTask = setTimeout(()=>{window.location.reload();}, 5000);
            } else {
                console.log(`Restart App was cancelled`)
            }     
        },
        rebootSystem() {
            console.log(`rebootSystem`);
            let text = "Do you really want to Reboot System?";
            if (confirm(text) == true) {
                sendMessageUnified(this.devId, 'rebootSystem', {});
                this.stopCommunication();
                this.loadingOverlay = {show: true, text: `Restating system...`};
                this.reloadTask = setTimeout(()=>{window.location.reload();}, 10000);
            } else {
                console.log(`Factory Reset was cancelled`)
            }  
        },
        factoryReset() {
            console.log(`factoryReset`);
            let text = "Do you really want to Factory Reset?";
            if (confirm(text) == true) {
                sendMessageUnified(this.devId, 'factoryReset', {});
                this.stopCommunication();
                this.loadingOverlay = {show: true, text: `Factory Reset...`};
                this.reloadTask = setTimeout(()=>{window.location.reload();}, 10000);
            } else {
                console.log(`Factory Reset was cancelled`)
            }             
        },
        checkFormat(format, value) {
            if (format === null) return true; // No check if no format provided
            let re = null
            // Ip V4 address in CIDR format. E.g. 192.168.1.123/24
            if (format === 'cidr') re = /^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\/([1-9]|[1-5][0-9]|6[0-4])$/;
            // Ip V4 address. E.g. 192.168.1.123
            else if (format === 'ip') re = /^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
            // 2 IP V4 separated by comma. E.g 1.1.1.1,1.0.0.1
            else if (format === '2ips') re = /^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5]),(\s*)((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/; 
            // Email address
            else if (format === 'email') re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            // Empty or email address
            else if (format === 'emptyoremail') re = /^$|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            // Any string with max 50 characters
            else if (format === 'max50chars') re = /^.{0,50}$/;
            // Any string with max 50 characters restricted to alphanumeric and '-' and '_'
            else if (format === 'max50restchars') re = /^[a-zA-Z0-9_-]{0,50}$/;
            // Any string with max 50 characters
            else if (format === 'max200chars') re = /^.{0,200}$/;
            // Any integer number [0, 24] for percentage
            else if (format === 'max24') re = /^((?:[0-9]|1[0-9]|2[0-3])(?:\.\d{1,2})?|24(?:\.00?)?)$/;
            // Any integer number [0, 100] for percentage
            else if (format === 'percentage') re = /^(0|[1-9][0-9]?|100)$/;
            // Any integer number [0, 65535] 
            else if (format === 'max65535') re = /^(0|6[0-5][0-5][0-3][0-5]|[1-5][0-9][0-9][0-9][0-9]|[1-9][0-9]{0,3})$/;
            // Device Id. eg. K9X7F5Q8H1N9
            else if (format === 'devid') re = /^[ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789]$/;
            // List (comma separated) of DevIds
            else if (format === 'listdevids') {
                re = /^[ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789][ABCDEFGHIJKLMNPQRSTUVWXYZ][123456789]$/;
                var elements = value.split(',')
                for (var element of elements) {
                    console.log(`ELEMENT: ${element}`);
                    if (! re.test(element.trim())) return false // If regular expression test fail in one of the elemnt --> not ok
                }
                return true
            }
            // List (comma separated) of Ips
            else if (format === 'listips') {
                re = /^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/; // IP regular expression
                elements = value.split(',')
                for (element of elements) {
                    if (! re.test(element.trim())) return false // If regular expression test fail in one of the elemnt --> not ok
                }
                return true
            }
            
            if (re) return re.test(value)
            else return false
        },
        stopCommunication() {
            console.log(`Stopping Settings communication to device: ${this.devId}`);
            clearInterval(this.getWiFiInfoTask);
            this.getWiFiInfoTask = null;
            clearInterval(this.getUserConfigTask);
            clearInterval(this.getCurrentSwPackageNameTask);
            clearInterval(this.getLatestSwPackageNameTask);
            
            if (this.devId) {
                // Close all WebRTC conections
                console.log(`Closing WebRTC connection to device: ${this.devId}`);
                // sendMessageUnified(this.devId, 'closeWrtcConnection', {});
                closeChannel('WebRTC', this.devId, appConfig.WebRTCCloseDelaySeconds);
            }
        }
    },
    computed: {
        servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not
        disableCheckForSwUpgrade: function () { return ! this.latestSwPackageName || this.latestSwPackageName.includes('Loading') || this.currSwPackName === this.latestSwPackageName},
    },
    // Lifecycle hooks
    mounted() {
        console.log('Settings View Created');
        document.title = `Settings-${this.devName}`; // Set Page title
        // Open spinner overlay
        if (this.devName) this.loadingOverlay = {show: true, text: `Connecting to ${this.devName}`};   
        else this.loadingOverlay = {show: true, text: `Connecting to Device`};                
        // Connect with Device
        openUnifiedChannel(this.devId, this.onDevConnected);
    },
    unmounted() {
        console.log('Settings View Unmounted');        
    },
    beforeUnmount() {
        console.log('Settings View beforeUnmount');
        this.stopCommunication();
        if (this.reloadTask) clearInterval(this.reloadTask)
    },
    errorCaptured() {console.log('Settings View errorCaptured')},
    // renderTracked() {console.log('Settings View renderTracked')},
})
export default class SettingsRKP extends Vue {}
</script>

<style scoped>
    .configName {
        font-weight: bold;
    }
    input {
        @apply border-gray-800 border-2;
    }
    select {
        @apply border-gray-800 border-2;
    }
    li {
        @apply p-1
    }
    label {
        @apply p-1
    }
    .menuButton {
        @apply border-gray-800 border-2 bg-gray-200  hover:bg-gray-400
    }
    .actionButton {
        @apply border-gray-800 border-2 bg-gray-200  hover:bg-gray-400  disabled:opacity-25 rounded-lg py-1 px-2;
    }

</style>


